﻿import { createLoginState } from "@/composables/createLoginState";
import { graphql } from "@/gql";
import type {
  LoadReportsSubscription,
  MseagFinanceExpenseReportLineItemInsertInput, MseagFinanceExpenseReportLineItemSetInput,
  MseagFinanceExpenseReportsInsertInput
} from "@/gql/graphql";
import { MseagFinanceExpenseReportStatusEnum } from "@/gql/graphql";
import { useTokenStore } from "@/stores/token-store";
import { useMutation, useSubscription } from "@vue/apollo-composable";
import { computed, ref } from "vue";

export const useReportStore = () => new ReportStore();
export type Report = LoadReportsSubscription["mseagFinanceExpenseReports"][number];

class ReportStore {

  private tokenStore = useTokenStore();

  public get reports(): Report[] {
    this.#enableReportQuery.value = true;
    return this.#reportQuery.result.value?.mseagFinanceExpenseReports ?? [];
  }

  #myReports = computed(() => this.reports.filter(r => r.createdByUserId === this.tokenStore.userId));
  get myReports() {
    return this.#myReports.value;
  }

  get myDraftReports() {
    return this.#myReports.value.filter(r => r.status === MseagFinanceExpenseReportStatusEnum.Draft);
  }

  get defaultReport() {
    return this.myReports.filter(f => f.status == MseagFinanceExpenseReportStatusEnum.Draft).at(-1)?.id;
  }
  get reportsLoading() {
    return this.#reportQuery.loading.value;
  }
  #enableReportQuery = ref(false);
  //language=GraphQL
  #reportQuery = useSubscription(
    graphql(`
        subscription loadReports {
            mseagFinanceExpenseReports(orderBy: {createdAt: DESC}) {
                id
                title
                createdAt
                status
                currency
                createdByUserId
                user {
                    name
                    avatar
                }
                lineItemsAggregate {
                    aggregate {
                        sum {
                            amount
                        }
                    }
                }
            }
        }
    `),
    undefined, () => ({enabled: this.#enableReportQuery.value})
  );

  //language=GraphQL
  private addExpenseLineItemMutation = useMutation(
    graphql(`
        mutation addExpenseLineItem(
            $object: MseagFinanceExpenseReportLineItemInsertInput!
        ) {
            insertMseagFinanceExpenseReportLineItemOne(object: $object) {
                id
            }
        }
    `)
  );

  async addExpenseLineItem(input: MseagFinanceExpenseReportLineItemInsertInput) {
    if (this.tokenStore.isAdmin) {
      input.createdByUserId = this.tokenStore.safeUserId;
    }
    const result = await this.addExpenseLineItemMutation.mutate({ object: input });
   
    const lineItem = result?.data?.insertMseagFinanceExpenseReportLineItemOne;
    if (!lineItem) {
      throw new Error(result?.errors?.at(0)?.toString() ?? "unknown error");
    }
    return lineItem;
  }

  //language=GraphQL
  private addNewReportMutation = useMutation(
    graphql(`
        mutation addNewReport($object: MseagFinanceExpenseReportsInsertInput!) {
            insertMseagFinanceExpenseReportsOne(object: $object) {
                id
                title
                createdAt
                status
                currency
                createdByUserId
                user {
                    name
                    avatar
                }
            }
        }
    `)
  );

  async addNewReport(input: MseagFinanceExpenseReportsInsertInput): Promise<Report> {
    if (this.tokenStore.isAdmin) {
      input.createdByUserId = this.tokenStore.userId;
      // input.approverUserId = this.tokenStore.approverId;
    }
    const result = await this.addNewReportMutation.mutate({ object: input })
    const report = result?.data?.insertMseagFinanceExpenseReportsOne;
    if (!report) {
      throw new Error(result?.errors?.at(0)?.toString() ?? "unknown error");
    }
    return {...report, lineItemsAggregate: {aggregate: {sum: {amount: 0}}}};
  }

  //language=GraphQL
  private deleteMutation = useMutation(
    graphql(`
        mutation deleteLineItem($id: Int!) {
            deleteMseagFinanceExpenseReportLineItemByPk(id: $id) {
                id
                reportId
            }
        }
    `)
  );
  async deleteDraftLineItem(lineItemId: number) {
    const result = await this.deleteMutation.mutate({ id: lineItemId });
    const deletedItem = result?.data?.deleteMseagFinanceExpenseReportLineItemByPk;
    return deletedItem;
  }


  //language=GraphQL
  private deleteTransferMutation = useMutation(
    graphql(`
        mutation deleteTransfer($id: Int!, $transferId: Int!) {
            deleteMseagFinanceExpenseReportLineItem(where: {id: {_in: [$id, $transferId]}}) {
                affected_rows
            }
        }
    `)
  );
  async deleteTransfer(lineItemId: number, transferId: number) {
    const result = await this.deleteTransferMutation.mutate({ id: lineItemId, transferId: transferId });
    return result?.data?.deleteMseagFinanceExpenseReportLineItem?.affected_rows === 2;
  }

  //language=GraphQL
  private updateLineItemMutation = useMutation(
    graphql(`
        mutation updateLineItem(
            $id: Int!
            $set: MseagFinanceExpenseReportLineItemSetInput!
        ) {
            updateMseagFinanceExpenseReportLineItemByPk(
                pk_columns: {id: $id},
                _set: $set
            ) {
                id
            }
        }
    `)
  );

  async updateLineItem(id: number, input: MseagFinanceExpenseReportLineItemSetInput) {
    const result = await this.updateLineItemMutation.mutate({ id, set: input });
    const lineItem = result?.data?.updateMseagFinanceExpenseReportLineItemByPk;
    if (!lineItem) {
      throw new Error(result?.errors?.at(0)?.toString() ?? "unknown error");
    }
    return lineItem.id;
  }
}
