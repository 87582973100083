﻿<template>
  <template v-if="loading">
    <v-progress-circular class="ma-4" indeterminate />
  </template>
  <template v-else>
    <v-list v-if="pendingExpensesStore.hasActions">
      <v-list-item
        :prepend-icon="mdiFileQuestion"
        to="expense-approval"
      >
        <v-list-item-title>
          You have expenses to approve
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <MyDraftAdvances v-if="advances.length > 0" :advances="advances" />
    <MyPendingApprovals v-if="approvals.length > 0" :approvals="approvals" />
    <MyPendingConfirmations v-if="confirmations.length > 0" :confirmations="confirmations" />
  </template>
  <PendingPayments v-if="$isAdmin" />
</template>

<script setup lang="ts">
import MyDraftAdvances from "@/components/Actions/MyDraftAdvances.vue";
import MyPendingConfirmations from "@/components/Actions/MyPendingConfirmations.vue";
import MyPendingApprovals from "@/components/Actions/MyPendingApprovals.vue";
import PendingPayments from "@/components/Actions/PendingPayments.vue";
import { MseagFinanceExpenseReportLineItemStatusEnum as StatusEnum } from "@/gql/graphql";
import { useCashApprovalStore } from "@/stores/cash-actions-store";
import { usePendingExpensesStore } from "@/stores/pending-expenses-store";
import { mdiCheck, mdiFileQuestion } from "@mdi/js";
import { computed } from "vue";

const pendingExpensesStore = usePendingExpensesStore();
const cashApprovalStore = useCashApprovalStore();
const { loading } = cashApprovalStore.loadMyLineItemActions();
const approvals = computed(() => {
  return cashApprovalStore.actions.filter(li => li.status === StatusEnum.Pending);
});
const confirmations = computed(() => {
  return cashApprovalStore.actions.filter(li => li.status === StatusEnum.Paid);
});
const advances = computed(() => {
  return cashApprovalStore.actions.filter(li => li.status === StatusEnum.Draft);
});
</script>

<style scoped>

</style>