﻿import { httpService } from "@/services/http-service";
import { useErrorStore } from "@/stores/error-store";
import { useTokenStore } from "@/stores/token-store";
import {
  ApolloClient,
  InMemoryCache, split
} from "@apollo/client/core";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

// HTTP connection to the API
const httpLink = new BatchHttpLink({
  uri: "/v1/graphql"
});

const wsLink = new GraphQLWsLink(createClient({
  url: () => `wss://${location.host}/v1/graphql`,
  lazyCloseTimeout: 1_000,
  connectionParams: async () => {
    const tokenStore = useTokenStore();
    if (tokenStore.isJwtExpired()) {
      await httpService.refreshJwt();
    }
    return {
      headers: {
        authorization: `Bearer ${tokenStore.currentJwt}`
      }
    };
  }
}));

const authLink = setContext(async (_, { headers }) => {
  const tokenStore = useTokenStore();
  if (tokenStore.isJwtExpired()) {
    await httpService.refreshJwt();
  }

  function createReturnValue() {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${tokenStore.currentJwt}`
      }
    };
  }

  return createReturnValue();
});

const errorLink = onError((error) => {
  console.error('graphql error', error);
  const errorStore = useErrorStore();
  errorStore.triggerError({
    message: error.graphQLErrors?.[0].message ?? error.networkError?.message ?? "unknown graphql error",
    origin: "graphql"
  });
});

const listMerge = (existing: any[], incoming: any[]) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-assignment
  return [...incoming];
};

// Cache implementation
const cache = new InMemoryCache({
  //these type policies are here to make sure that when the cache gets updated with incoming data
  //we drop the old data. This is so that any records that get removed are properly updated in the cache
  typePolicies: {
    MseagFinanceExpenseReports: {
      fields: {
        line_items: {
          merge: listMerge
        }
      }
    },
    MseagFinanceCostCenters: {
      fields: {
        accounts: {
          merge: listMerge
        }
      }
    },
    Subscription: {
      fields: {
        mseagFinanceExpenseReportLineItem: {
          merge: listMerge
        },
        mseagFinanceChannels: {
          merge: listMerge
        },
        mseagFinanceExpenseReports: {
          merge: listMerge
        }
      }
    }
  }
});

const splitLink = split(({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(splitLink)),
  cache,
  connectToDevTools: true,
  defaultOptions: {
    watchQuery: {
      // fetchPolicy: 'no-cache',
      errorPolicy: "ignore"
    },
    query: {
      // fetchPolicy: 'no-cache',
      errorPolicy: "all"
    }
  }
});

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
globalThis.apolloClient = apolloClient;