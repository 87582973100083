﻿import { graphql } from "@/gql";
import type { LoadMyPendingExpensesSubscription } from "@/gql/graphql";
import { useTokenStore } from "@/stores/token-store";
import { useSubscription } from "@vue/apollo-composable";

export type PendingExpenseLineItem = LoadMyPendingExpensesSubscription["mseagFinanceExpenseReportLineItem"][number];
export const usePendingExpensesStore = () => new PendingExpensesStore();
class PendingExpensesStore {
  private tokenStore = useTokenStore();
  //language=GraphQL
  #hasActionsQuery = useSubscription(
    graphql(`
        subscription loadHasPendingExpenses($userId: Int!) {
            mseagFinanceExpenseReportLineItemAggregate(
                where: {
                    type: {_in: [EXPENSE] },
                    status: {_eq: PENDING},
                    account: {
                        cost_center: {
                            approverUserId: {_eq: $userId}
                        }
                    }
                }
            ) {
                aggregate {
                    count
                }
            }
        }
    `),
    { userId: this.tokenStore.userId ?? -1 }
  );

  get hasActions(): boolean {
    return (this.#hasActionsQuery.result.value?.mseagFinanceExpenseReportLineItemAggregate?.aggregate?.count ?? 0) > 0;
  }
  
  //language=GraphQL
  #myActionsQuery = useSubscription(
    graphql(`
        subscription loadMyPendingExpenses($userId: Int!) {
            mseagFinanceExpenseReportLineItem(
                where: {
                    type: {_in: [EXPENSE] },
                    status: {_eq: PENDING},
                    account: {
                        cost_center: {
                            approverUserId: {_eq: $userId}
                        }
                    }
                }
            ) {
                id
                createdAt
                image
                transfer {
                    id
                    image
                }
                status
                type
                amount
                destination
                accountId
                currency
                purpose
                reportId
                created_by_user {
                    id
                    avatar
                    name
                }
                channel {
                    id
                    name
                }
            }
        }
    `),
    { userId: this.tokenStore.userId ?? -1 }, {enabled: true}
  );
  
  get actions(): PendingExpenseLineItem[] {
    return this.#myActionsQuery.result.value?.mseagFinanceExpenseReportLineItem ?? [];
  }

  loadMyLineItemActions() {
    if (!this.tokenStore.userId) {
      throw new Error("no user id to load with");
    }
    this.#myActionsQuery.start();
    return { loading: this.#myActionsQuery.loading };
  }
}

