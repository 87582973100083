﻿<template>
  <div id="g_popup" class="fill-height position-fixed ma-4" style="width: 100%; z-index: 100; pointer-events: none;" />
  <v-container class="login-main">
    <div id="g_id_signin" />
    <div v-if="$isDev">
      <v-text-field v-model="customJwt" label="Custom JWT" @keyup.enter="loginStore.loginUsingToken(customJwt)" />
      <v-btn color="primary" @click="autoLogin('admin@test.com')">
        Login: Admin
      </v-btn>
      <v-btn color="primary" @click="autoLogin('manager@test.com')">
        Login: Manager
      </v-btn>
      <v-btn color="primary" @click="autoLogin('userA@test.com')">
        Login: User A
      </v-btn>
      <v-btn color="primary" @click="autoLogin('userB@test.com')">
        Login: User B
      </v-btn>
    </div>
    <v-alert v-if="user && !user.enabled" type="error">
      Account for: {{ user.name }} is disabled, maybe try a different account?
    </v-alert>
    <v-alert v-if="validationError" type="error">
      <template v-if="validationError.code == 'not-sil'">
        You attempted to login with a non SIL email,
        request an admin to add your email if you still need access with this account.
        Otherwise login with your SIL account.
      </template>
      <template v-else>
        {{ validationError.title }}
      </template>
    </v-alert>
  </v-container>
</template>
<script setup lang="ts">
import { useAppTitle } from "@/composables/useAppTitle";

/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access */
import { useLoginStore, type User } from "@/stores/login-store";
import { computed, onMounted, onUnmounted, ref } from "vue";
useAppTitle('Login');
const customJwt = ref("");

const loginStore = useLoginStore();
const user = ref<User | undefined>(undefined);
const validationError = computed(() => {
  let response = loginStore.lastValidateResponse.value;
  if (response && 'title' in response) {
    return response;
  }
  return undefined;
});

const { off } = loginStore.loggedIn.on(param => {
  user.value = param;
  if (!user.value.enabled) {
    promptLogin();
  }
});
onUnmounted(() => off());

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore: Modifiers cannot appear here
declare let google: any;
onMounted(async () => {
  loginStore.lastValidateResponse.value = undefined;
  await loginStore.initGoogle();
  promptLogin();
});

function promptLogin() {
  google.accounts.id.prompt();
  google.accounts.id.renderButton(
    document.getElementById("g_id_signin"),
    {
      type: "standard",
      shape: "rectangular",
      theme: "outline",
      text: "signin_with",
      size: "large",
      locale: "en"
    }
  );
}

async function autoLogin(email: string) {
  const results = await fetch(`/api/Testing/make-jwt?email=${email}`);
  if (!results.ok) {
    throw new Error("Failed to get JWT");
  }
  const jwt = await results.text();
  await loginStore.loginUsingToken(jwt);
}
</script>

<style>
#g_popup > * {
  pointer-events : initial;
}

.login-main {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 180px;
  grid-template-rows: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}
</style>